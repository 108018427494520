export const lMSApiHandler = {
  users: {
    wishlist: {
      wishlistCourse: {
        method: 'POST',
        path: (payload: {
          userId: string | number;
          courseId: string | number;
        }) => `users/${payload.userId}/wishlisted-courses/${payload.courseId}`,
      },
      unWishlistCourse: {
        method: 'DELETE',
        path: (payload: {
          userId: string | number;
          courseId: string | number;
        }) => `users/${payload.userId}/wishlisted-courses/${payload.courseId}`,
      },
      getWishlistedCourses: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/wishlisted-courses`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    enrollment: {
      enrollInCourse: {
        method: 'POST',
        path: (payload: {
          userId: string | number;
          courseId: string | number;
        }) => `users/${payload.userId}/enrolled-courses/${payload.courseId}`,
      },
      getEnrolledCourses: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/enrolled-courses`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          showOnlyCompletedCourses?: string | number;
          showOnlyUncompletedCourses?: string | number;
        },
      },
    },
    subsections: {
      finishCourseSubsection: {
        method: 'POST',
        path: (payload: {
          userId: string | number;
          subsectionId: string | number;
        }) =>
          `users/${payload.userId}/finished-subsections/${payload.subsectionId}`,
      },
      unFinishCourseSubsection: {
        method: 'DELETE',
        path: (payload: {
          userId: string | number;
          subsectionId: string | number;
        }) =>
          `users/${payload.userId}/finished-subsections/${payload.subsectionId}`,
      },
    },
    courseCertificates: {
      getUsersCertificate: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/course-certificates`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    administeredCourses: {
      myCourses: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/my-courses`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          orderByThisDoesntWorkCheckPostman?: (string | number)[];
          search?: string | number;
        },
      },
      instructedCourses: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `users/${payload.userId}/instructed-courses`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          sort?: string | number;
        },
      },
    },
    pinCourse: {
      method: 'PATCH',
      path: (payload: { userId: string | number; courseId: string | number }) =>
        `users/${payload.userId}/my-courses/${payload.courseId}`,
    },
    getUsersActivityLog: {
      method: 'GET',
      path: (payload: { userId: string | number }) =>
        `users/${payload.userId}/activity-log`,
      params: {} as {
        skip?: string | number;
        take?: string | number;
        activityType?: (string | number)[];
        language?: string | number;
      },
    },
  },
  courses: {
    sections: {
      addSection: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/sections`,
      },
      getStudentSections: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/sections`,
      },
      getInstructorsSections: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/sections/instructors`,
      },
      reorderSections: {
        method: 'PATCH',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/sections/order`,
      },
      getLastVisitedSubsection: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/sections/last-visited-subsection`,
      },
      getStudentProgress: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/sections/progress`,
      },
    },
    subsections: {
      getCourseSubsectionIds: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/subsectionIds`,
      },
    },
    announcements: {
      addAnnouncement: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/announcements`,
      },
      getAnnouncementsForStudents: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/announcements`,
        params: {} as {
          limit?: string | number;
          offset?: string | number;
          sort?: string | number;
        },
      },
      getAnnouncementsForInstructor: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/announcements/instructor`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          sort?: string | number;
        },
      },
    },
    assignments: {
      createCourseAssignment: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/assignments`,
      },
      getCourseAssignmentsStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/assignments`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getCourseAssignmentsInstructor: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/assignments/instructor`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    gradeItems: {
      createCourseGradeItem: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/grade-items`,
      },
      getCourseGradeItemsForStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/grade-items`,
        params: {} as { limit?: string | number; offset?: string | number },
      },
      getCourseGradeItemsForInstructor: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/grade-items/instructor`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    rubrics: {
      createRubric: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/rubrics`,
      },
      getCourseRubrics: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/rubrics`,
        params: {} as { take?: string | number; skip?: string | number },
      },
    },
    groups: {
      students: {
        moveStudents: {
          method: 'PATCH',
          path: (payload: { courseId: string | number }) =>
            `courses/${payload.courseId}/groups/students`,
        },
      },
      createGroup: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/groups`,
      },
      generateGroupMappings: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/groups/generate`,
      },
      createGroups: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/groups/bulk`,
      },
      getCourseGroups: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/groups`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getCurrentGroup: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/groups/current`,
      },
    },
    onlineLectures: {
      createOnlineLecture: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/online-lectures`,
      },
      getOnlineLectures: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/online-lectures`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    certificates: {
      getCertificatesReport: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/certificates`,
        params: {} as { organizationId?: string | number },
      },
      issueCertificates: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/certificates`,
      },
      getCertificateTemplates: {
        method: 'GET',
        path: () => `course-certificates/templates`,
      },
    },
    surveys: {
      createCourseSurvey: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/surveys`,
      },
      getCourseSurveysInstructor: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/surveys/instructor`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          type?: string | number;
        },
      },
      getCourseSurveysStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/surveys`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          type?: string | number;
        },
      },
    },
    grades: {
      getAllMyGradesStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/grades`,
      },
      getCourseGradingSchemaStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/grading-schema`,
      },
    },
    instructors: {
      getInstructors: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/instructors`,
      },
    },
    enrolledStudents: {
      getCoursesEnrolledStudents: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/enrolled-students`,
        params: {} as {
          skip?: string | number;
          take?: string | number;
          search?: string | number;
        },
      },
      getCourseUsersStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/users/`,
        params: {} as { search?: string | number },
      },
      getCourseUsersInstructor: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/users/instructor`,
        params: {} as { search?: string | number },
      },
    },
    notes: {
      getCourseNotes: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/notes`,
      },
    },
    reports: {
      getAttendanceReport: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/enrolled-students/reports/attendance`,
        params: {} as { organizationId?: string | number },
      },
      getCompletionReport: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/enrolled-students/reports/completion`,
      },
      getGradesReport: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/enrolled-students/reports/grades`,
        params: {} as {
          search?: string | number;
          filteredGradeItemIds?: (string | number)[];
          sort?: string | number;
          organizationId?: string | number;
        },
      },
    },
    completion: {
      getCourseIsCompletedFlag: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/is-completed`,
      },
    },
    attendance: {
      takeCourseAttendance: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/attendance`,
      },
      getCourseAttendance: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/attendance`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getStudentCourseAttendance: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/attendance/student`,
      },
      takeAttendanceByCode: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/attendance/take`,
      },
      sendEnrollmentEmails: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/send-enrollment-emails`,
      },
    },
    enrollment: {
      enrollInCourse: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/enrollment`,
      },
      checkUserEnrollmentInCourse: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/enrollment`,
      },
      getOverlappingCourses: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/overlap`,
      },
    },
    cloneCourse: {
      cloneCourse: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `clone/${payload.courseId}/`,
      },
      cloneExam: {
        method: 'POST',
        path: (payload: {
          courseId: string | number;
          subsectionExamId: string | number;
        }) => `clone/${payload.courseId}/exam/${payload.subsectionExamId}`,
      },
    },
    discussionBoardV2: {
      createCourseDiscussionBoard: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/discussion-boards`,
      },
      getCourseDiscussionBoards: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/discussion-boards`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getAllCourseDiscussionBoardsCommentsStudent: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/discussion-boards/comments`,
      },
      getAllCourseDiscussionBoardsCommentsInstructor: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/discussion-boards/comments/instructor`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getStudentEngagement: {
        method: 'GET',
        path: (payload: {
          courseId: string | number;
          studentId: string | number;
        }) =>
          `courses/${payload.courseId}/discussion-boards/${payload.studentId}/engagement`,
      },
    },
    translations: {
      getCourseTranslations: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/translations`,
      },
      upsertCourseTranslation: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/translations`,
      },
      getCourseTranslation: {
        method: 'GET',
        path: (payload: {
          courseId: string | number;
          language: string | number;
        }) => `courses/${payload.courseId}/translations/${payload.language}`,
      },
      deleteCourseTranslation: {
        method: 'DELETE',
        path: (payload: {
          courseId: string | number;
          language: string | number;
        }) => `courses/${payload.courseId}/translations/${payload.language}`,
      },
    },
    approvalRequests: {
      getCourseApprovalRequest: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/request-approval`,
      },
      requestCourseApproval: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/request-approval`,
      },
    },
    onCampusLectures: {
      createOnCampusLectures: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/on-campus-lectures`,
      },
      getOnCampusLectures: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/on-campus-lectures`,
        params: {} as { skip?: string | number; take?: string | number },
      },
    },
    interests: {
      getInterest: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/interests`,
      },
      addInterest: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/interests`,
      },
      removeInterest: {
        method: 'DELETE',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/interests`,
      },
    },
    confirmationCheckbox: {
      getCourseConfirmationCheckbox: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/confirmation-checkboxes`,
      },
      getCourseConfirmationCheckboxesResponses: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/confirmation-checkboxes/responses`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      upsertCourseConfirmationCheckboxes: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/confirmation-checkboxes`,
      },
      deleteCourseConfirmationCheckbox: {
        method: 'DELETE',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/confirmation-checkboxes`,
      },
      respondToCourseConfirmationCheckbox: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/confirmation-checkboxes/respond`,
      },
    },
    createCourse: {
      method: 'POST',
      path: () => `courses`,
    },
    updateCourse: {
      method: 'PATCH',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}`,
    },
    getCourses: {
      method: 'GET',
      path: () => `courses`,
      params: {} as {
        skip?: string | number;
        take?: string | number;
        minPrice?: string | number;
        maxPrice?: string | number;
        languages?: (string | number)[];
        sort?: string | number;
        learningTypes?: (string | number)[];
        filterOutEnrolledCourses?: string | number;
        showOnlyEnrolledCourses?: string | number;
        search?: string | number;
        startDate?: string | number;
        startEnrollmentDate?: string | number;
        isCertificateIssuedManually?: string | number;
        filterOutUntimedCourses?: string | number;
        expiryDate?: string | number;
      },
    },
    getCoursesFilterOptions: {
      method: 'GET',
      path: () => `courses/filter-options`,
    },
    getCourse: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}`,
    },
    getCourseInstructor: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/instructor`,
    },
    deleteCourse: {
      method: 'DELETE',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}`,
    },
    updateCoursePublishStatus: {
      method: 'PATCH',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/is-published`,
    },
    getCoursePassingCriteria: {
      method: 'GET',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/passing-criteria`,
    },
    updateCoursePassingCriteria: {
      method: 'PUT',
      path: (payload: { courseId: string | number }) =>
        `courses/${payload.courseId}/passing-criteria`,
    },
    courseReview: {
      create: {
        method: 'POST',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/reviews`,
      },
      getById: {
        method: 'GET',
        path: (payload: {
          courseId: string | number;
          reviewId: string | number;
        }) => `courses/${payload.courseId}/reviews/${payload.reviewId}`,
      },
      getMyReview: {
        method: 'GET',
        path: (payload: {
          courseId: string | number;
          userId: string | number;
        }) => `courses/${payload.courseId}/reviews?userId=${payload.userId}`,
      },
      getAllByCourse: {
        method: 'GET',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/reviews`,
      },
      update: {
        method: 'PATCH',
        path: (payload: { courseId: string | number }) =>
          `courses/${payload.courseId}/reviews`,
      },
      delete: {
        method: 'DELETE',
        path: (payload: {
          courseId: string | number;
          reviewId: string | number;
        }) => `courses/${payload.courseId}/reviews/${payload.reviewId}`,
      },
    },
  },
  courseResources: {
    'courseSections/Modules': {
      subsections: {
        addSubsection: {
          method: 'POST',
          path: (payload: { sectionId: string | number }) =>
            `course-sections/${payload.sectionId}/subsection`,
        },
        reorderSubsections: {
          method: 'PATCH',
          path: (payload: { sectionId: string | number }) =>
            `course-sections/${payload.sectionId}/order`,
        },
      },
      notes: {
        getSectionNotes: {
          method: 'GET',
          path: (payload: { sectionId: string | number }) =>
            `course-sections/${payload.sectionId}/notes`,
        },
      },
      getSection: {
        method: 'GET',
        path: (payload: { sectionId: string | number }) =>
          `course-sections/${payload.sectionId}`,
      },
      updateSection: {
        method: 'PATCH',
        path: (payload: { sectionId: string | number }) =>
          `course-sections/${payload.sectionId}`,
      },
      removeSection: {
        method: 'DELETE',
        path: (payload: { sectionId: string | number }) =>
          `course-sections/${payload.sectionId}`,
      },
    },
    courseSubsections: {
      attachments: {
        addAttachment: {
          method: 'POST',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/attachments`,
        },
        getSubsectionAttachments: {
          method: 'GET',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/attachments`,
        },
        editAttachmentName: {
          method: 'PATCH',
          path: (payload: {
            subsectionId: string | number;
            attachmentId: string | number;
          }) =>
            `course-subsections/${payload.subsectionId}/attachments/${payload.attachmentId}`,
        },
        removeAttachment: {
          method: 'DELETE',
          path: (payload: {
            subsectionId: string | number;
            attachmentId: string | number;
          }) =>
            `course-subsections/${payload.subsectionId}/attachments/${payload.attachmentId}`,
        },
        addSingleAttachment: {
          method: 'POST',
          path: () => `course-subsections/798/attachments/single`,
        },
      },
      notes: {
        addNote: {
          method: 'POST',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/notes`,
        },
        deleteNote: {
          method: 'DELETE',
          path: (payload: {
            subsectionId: string | number;
            noteId: string | number;
          }) =>
            `course-subsections/${payload.subsectionId}/notes/${payload.noteId}`,
        },
        getSubsectionNotes: {
          method: 'GET',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/notes`,
        },
        editNote: {
          method: 'PATCH',
          path: (payload: {
            subsectionId: string | number;
            noteId: string | number;
          }) =>
            `course-subsections/${payload.subsectionId}/notes/${payload.noteId}`,
        },
      },
      attendance: {
        attendanceAtTheModuleLevel: {
          attendanceModule: {
            method: 'PATCH',
            path: (payload: {
              courseId: string | number;
              sectionId: string | number;
            }) =>
              `courses/${payload.courseId}/sections/${payload.sectionId}/students`,
          },
          getAttendanceModule: {
            method: 'GET',
            path: (payload: {
              courseId: string | number;
              sectionId: string | number;
            }) =>
              `courses/${payload.courseId}/sections/${payload.sectionId}/students`,
          },
        },
        getStudentsWhoFinishedThisSubsection: {
          method: 'GET',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/students`,
        },
        takeAttendance: {
          method: 'PATCH',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/students`,
        },
      },
      completion: {
        finishCourseSubsection: {
          method: 'POST',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/complete`,
        },
        unfinishCourseSubsection: {
          method: 'DELETE',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/complete`,
        },
      },
      questionnaire: {
        createQuestionnaire: {
          method: 'POST',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/questionnaire`,
        },
        deleteQuestionnaire: {
          method: 'DELETE',
          path: (payload: { subsectionId: string | number }) =>
            `course-subsections/${payload.subsectionId}/questionnaire`,
        },
      },
      updateSubsection: {
        method: 'PATCH',
        path: (payload: { subsectionId: string | number }) =>
          `course-subsections/${payload.subsectionId}`,
      },
      deleteSubsection_Lecture: {
        method: 'DELETE',
        path: (payload: { subsectionId: string | number }) =>
          `course-subsections/${payload.subsectionId}`,
      },
      getSubsection: {
        method: 'GET',
        path: (payload: { subsectionId: string | number }) =>
          `course-subsections/${payload.subsectionId}`,
      },
      getSubsectionInstructor: {
        method: 'GET',
        path: (payload: { subsectionId: string | number }) =>
          `course-subsections/${payload.subsectionId}/instructor`,
      },
    },
    courseAssignments: {
      submissions: {
        getCourseAssignmentSubmissionsInstructor: {
          method: 'GET',
          path: (payload: { assignmentId: string | number }) =>
            `course-assignments/${payload.assignmentId}/submissions`,
          params: {} as { skip?: string | number; take?: string | number },
        },
        submitSolutionForCourseAssignmentStudent: {
          method: 'POST',
          path: (payload: { assignmentId: string | number }) =>
            `course-assignments/${payload.assignmentId}/submissions`,
        },
        getSingleSubmission: {
          method: 'GET',
          path: (payload: {
            assignmentId: string | number;
            submissionId: string | number;
          }) =>
            `course-assignments/${payload.assignmentId}/submissions/${payload.submissionId}`,
        },
      },
      updateCourseAssignment: {
        method: 'PATCH',
        path: (payload: { assignmentId: string | number }) =>
          `course-assignments/${payload.assignmentId}`,
      },
      deleteCourseAssignment: {
        method: 'DELETE',
        path: (payload: { assignmentId: string | number }) =>
          `course-assignments/${payload.assignmentId}`,
      },
      getCourseAssignmentStudent: {
        method: 'GET',
        path: (payload: { assignmentId: string | number }) =>
          `course-assignments/${payload.assignmentId}`,
      },
      getCourseAssignmentInstructor: {
        method: 'GET',
        path: (payload: { assignmentId: string | number }) =>
          `course-assignments/${payload.assignmentId}/instructor`,
      },
    },
    courseGroups: {
      updateCourseGroup: {
        method: 'PATCH',
        path: (payload: { courseGroupId: string | number }) =>
          `course-groups/${payload.courseGroupId}`,
      },
      deleteCourseGroup: {
        method: 'DELETE',
        path: (payload: { courseGroupId: string | number }) =>
          `course-groups/${payload.courseGroupId}`,
      },
    },
    courseRubrics: {
      putCourseRubric: {
        method: 'PUT',
        path: (payload: { rubricId: string | number }) =>
          `course-rubrics/${payload.rubricId}`,
      },
      getCourseRubric: {
        method: 'GET',
        path: (payload: { rubricId: string | number }) =>
          `course-rubrics/${payload.rubricId}`,
      },
      deleteRubric: {
        method: 'DELETE',
        path: (payload: { rubricId: string | number }) =>
          `course-rubrics/${payload.rubricId}`,
      },
    },
    courseAnnouncements: {
      editAnnouncement: {
        method: 'PATCH',
        path: (payload: { announcementId: string | number }) =>
          `course-announcements/${payload.announcementId}`,
      },
      getSingleAnnouncement: {
        method: 'GET',
        path: (payload: { announcementId: string | number }) =>
          `course-announcements/${payload.announcementId}`,
      },
      deleteAnnouncement: {
        method: 'DELETE',
        path: (payload: { announcementId: string | number }) =>
          `course-announcements/${payload.announcementId}`,
      },
    },
    courseGradeItems: {
      grades: {
        rubricAssessment: {
          getStudentRubricAssessment: {
            method: 'GET',
            path: (payload: {
              gradeItemId: string | number;
              studentId: string | number;
            }) =>
              `course-grade-items/${payload.gradeItemId}/grades/${payload.studentId}`,
          },
          assessStudent: {
            method: 'POST',
            path: (payload: {
              gradeItemId: string | number;
              studentId: string | number;
            }) =>
              `course-grade-items/${payload.gradeItemId}/grades/${payload.studentId}/assessment`,
          },
          getCourseGradeItemRubric: {
            method: 'GET',
            path: (payload: { gradeItemId: string | number }) =>
              `course-grade-items/${payload.gradeItemId}/rubric`,
          },
        },
        createItemGrade: {
          getStudentItemGrade: {
            method: 'GET',
            path: (payload: {
              gradeItemId: string | number;
              studentId: string | number;
            }) =>
              `course-grade-items/${payload.gradeItemId}/grades/${payload.studentId}`,
          },
        },
        groupRubricAssessment: {
          getGroupAssessment: {
            method: 'GET',
            path: (payload: {
              gradeItemId: string | number;
              groupId: string | number;
            }) =>
              `course-grade-items/${payload.gradeItemId}/grades/groups/${payload.groupId}`,
          },
          assessGroup: {
            method: 'POST',
            path: (payload: {
              gradeItemId: string | number;
              groupId: string | number;
            }) =>
              `course-grade-items/${payload.gradeItemId}/grades/groups/${payload.groupId}/assessment`,
          },
        },
        getCourseGradeItemGrades: {
          method: 'GET',
          path: (payload: { gradeItemId: string | number }) =>
            `course-grade-items/${payload.gradeItemId}/grades`,
        },
        patchCourseGradeItemGrades: {
          method: 'PATCH',
          path: (payload: { gradeItemId: string | number }) =>
            `course-grade-items/${payload.gradeItemId}/grades`,
        },
      },
      getCourseGradeItem: {
        method: 'GET',
        path: (payload: { gradeItemId: string | number }) =>
          `course-grade-items/${payload.gradeItemId}`,
      },
      getCourseGradeItemRubric: {
        method: 'GET',
        path: (payload: { gradeItemId: string | number }) =>
          `course-grade-items/${payload.gradeItemId}/rubric`,
      },
      deleteCourseGradeItem: {
        method: 'DELETE',
        path: (payload: { gradeItemId: string | number }) =>
          `course-grade-items/${payload.gradeItemId}`,
      },
      updateCourseGradeItem: {
        method: 'PATCH',
        path: (payload: { gradeItemId: string | number }) =>
          `course-grade-items/${payload.gradeItemId}`,
      },
    },
    courseAttendance: {
      updateCourseAttendance: {
        method: 'PUT',
        path: (payload: { attendanceId: string | number }) =>
          `course-attendance/${payload.attendanceId}`,
      },
      getCourseAttendanceById: {
        method: 'GET',
        path: (payload: { attendanceId: string | number }) =>
          `course-attendance/${payload.attendanceId}`,
      },
      deleteCourseAttendance: {
        method: 'DELETE',
        path: (payload: { attendanceId: string | number }) =>
          `course-attendance/${payload.attendanceId}`,
      },
    },
    courseOnlineLectures: {
      getSingleOnlineLecture: {
        method: 'GET',
        path: (payload: { onlineLectureId: string | number }) =>
          `course-online-lectures/${payload.onlineLectureId}`,
      },
      updateSingleOnlineLecture: {
        method: 'PATCH',
        path: (payload: { onlineLectureId: string | number }) =>
          `course-online-lectures/${payload.onlineLectureId}`,
      },
      deleteOnlineLecture: {
        method: 'DELETE',
        path: (payload: { onlineLectureId: string | number }) =>
          `course-online-lectures/${payload.onlineLectureId}`,
      },
      joinOnlineLectureAsInstructor: {
        method: 'GET',
        path: (payload: { onlineLectureId: string | number }) =>
          `course-online-lectures/${payload.onlineLectureId}/join-as-instructor`,
      },
    },
    courseSurveys: {
      surveyPolls: {
        addPollChoice: {
          method: 'POST',
          path: (payload: { pollId: string | number }) =>
            `survey-polls/${payload.pollId}/choices`,
        },
        editPollChoice: {
          method: 'PATCH',
          path: (payload: {
            pollId: string | number;
            choiceId: string | number;
          }) => `survey-polls/${payload.pollId}/choices/${payload.choiceId}`,
        },
        deletePollChoice: {
          method: 'DELETE',
          path: (payload: {
            pollId: string | number;
            choiceId: string | number;
          }) => `survey-polls/${payload.pollId}/choices/${payload.choiceId}`,
        },
        surveyPollVote_S: {
          method: 'POST',
          path: (payload: { pollId: string | number }) =>
            `survey-polls/${payload.pollId}/vote`,
        },
        getPollById: {
          method: 'GET',
          path: (payload: { pollId: string | number }) =>
            `survey-polls/${payload.pollId}`,
        },
        surveyPollUnvote: {
          method: 'POST',
          path: (payload: { pollId: string | number }) =>
            `survey-polls/${payload.pollId}/unvote`,
        },
        editPoll: {
          method: 'PATCH',
          path: (payload: { pollId: string | number }) =>
            `survey-polls/${payload.pollId}`,
        },
        deletePoll: {
          method: 'DELETE',
          path: (payload: { pollId: string | number }) =>
            `survey-polls/${payload.pollId}`,
        },
      },
      getSurveyById_I: {
        method: 'GET',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}/instructor`,
      },
      getSurveyById_S: {
        method: 'GET',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}`,
      },
      getSurveySettings: {
        method: 'GET',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}/settings`,
      },
      addPollToSurvey_I: {
        method: 'POST',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}/polls`,
      },
      updateCourseSurvey_I: {
        method: 'PATCH',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}`,
      },
      updateSurveySettings_I: {
        method: 'PATCH',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}/settings`,
      },
      deleteCourseSurvey_I: {
        method: 'DELETE',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}`,
      },
      getSurveyCSV: {
        method: 'GET',
        path: (payload: { surveyId: string | number }) =>
          `course-surveys/${payload.surveyId}/csv`,
      },
    },
    courseDiscussionBoards: {
      discussionBoardComments: {
        getDiscussionBoardCommentById: {
          method: 'GET',
          path: (payload: { commentId: string | number }) =>
            `discussion-board-comments/${payload.commentId}`,
        },
        editDiscussionBoardComment: {
          method: 'PATCH',
          path: (payload: { commentId: string | number }) =>
            `discussion-board-comments/${payload.commentId}`,
        },
        deleteDiscussionBoardComment: {
          method: 'DELETE',
          path: (payload: { commentId: string | number }) =>
            `discussion-board-comments/${payload.commentId}`,
        },
        likeDiscussionBoardComment: {
          method: 'POST',
          path: (payload: { commentId: string | number }) =>
            `discussion-board-comments/${payload.commentId}/like`,
        },
        unlikeDiscussionBoardComment: {
          method: 'DELETE',
          path: (payload: { commentId: string | number }) =>
            `discussion-board-comments/${payload.commentId}/like`,
        },
      },
      getDiscussionBoardById: {
        method: 'GET',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}`,
      },
      editDiscussionBoard: {
        method: 'PATCH',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}`,
      },
      deleteDiscussionBoard: {
        method: 'DELETE',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}`,
      },
      getDiscussionBoardComments: {
        method: 'GET',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}/comments`,
      },
      getDiscussionBoardCommentsInstructor: {
        method: 'GET',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}/comments/instructor`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      commentOnDiscussionBoardStudent: {
        method: 'POST',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}/comments`,
      },
      commentOnDiscussionBoardInstructor: {
        method: 'POST',
        path: (payload: { discussionBoardId: string | number }) =>
          `course-discussion-boards/${payload.discussionBoardId}/comments/instructor`,
      },
    },
    courseOnCampusLectures: {
      getOnCampusLecture: {
        method: 'GET',
        path: (payload: { onCampusLectureId: string | number }) =>
          `course-on-campus-lectures/${payload.onCampusLectureId}`,
      },
      updateOnCampusLecture: {
        method: 'PATCH',
        path: (payload: { onCampusLectureId: string | number }) =>
          `course-on-campus-lectures/${payload.onCampusLectureId}`,
      },
      deleteOnCampusLecture: {
        method: 'DELETE',
        path: (payload: { onCampusLectureId: string | number }) =>
          `course-on-campus-lectures/${payload.onCampusLectureId}`,
      },
    },
  },
  courseCertificates: {
    getStudentCourseCertificates: {
      method: 'GET',
      path: (payload: { userId: string | number }) =>
        `users/${payload.userId}/course-certificates`,
    },
    getCertificate: {
      method: 'GET',
      path: (payload: { certificateId: string | number }) =>
        `course-certificates/${payload.certificateId}`,
    },
  },
  courseCategories: {
    createLearningCategory: {
      method: 'POST',
      path: () => `course-categories`,
    },
    getLearningCategories: {
      method: 'GET',
      path: () => `course-categories`,
    },
    getLearningCategory: {
      method: 'GET',
      path: (payload: { categoryId: string | number }) =>
        `course-categories/${payload.categoryId}`,
    },
    updateLearningCategory: {
      method: 'PATCH',
      path: (payload: { categoryId: string | number }) =>
        `course-categories/${payload.categoryId}`,
    },
    deleteLearningCategory: {
      method: 'DELETE',
      path: (payload: { categoryId: string | number }) =>
        `course-categories/${payload.categoryId}`,
    },
  },
  studentChecklists: {
    markChecklistItemAsComplete: {
      method: 'POST',
      path: (payload: {
        checklistId: string | number;
        checklistItemId: string | number;
      }) =>
        `student-checklists/${payload.checklistId}/items/${payload.checklistItemId}`,
    },
    deleteChecklistItemFromCompletedItems: {
      method: 'DELETE',
      path: (payload: {
        checklistId: string | number;
        checklistItemId: string | number;
      }) =>
        `student-checklists/${payload.checklistId}/items/${payload.checklistItemId}`,
    },
    getUserChecklists: {
      method: 'GET',
      path: (payload: { userId: string | number }) =>
        `users/${payload.userId}/checklists`,
    },
  },
  checklists: {
    getAvailableChecklists: {
      method: 'GET',
      path: () => `checklists`,
      params: {} as { skip?: string | number; take?: string | number },
    },
    enrollInChecklist: {
      method: 'POST',
      path: (payload: { checklistId: string | number }) =>
        `checklists/${payload.checklistId}/enroll`,
    },
  },
  specializations: {
    getSpecializations: {
      method: 'GET',
      path: () => `specializations`,
      params: {} as { skip?: string | number; take?: string | number },
    },
    getSingleSpecialization: {
      method: 'GET',
      path: (payload: { specializationId: string | number }) =>
        `specializations/${payload.specializationId}`,
    },
    enrollInSpecialization: {
      method: 'POST',
      path: (payload: { specializationId: string | number }) =>
        `specializations/${payload.specializationId}/enrollment`,
    },
  },
  externalCourses: {
    admin: {
      getEnrolleesForExternalCourse: {
        method: 'GET',
        path: (payload: {
          externalCourseTargetOrganizationId: string | number;
        }) =>
          `external-courses/enrollees/${payload.externalCourseTargetOrganizationId}`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getAllImportedExtCoursesForOrg: {
        method: 'GET',
        path: (payload: { organizationId: string | number }) =>
          `external-courses/imported-courses/${payload.organizationId}`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getSingleTargetOrgForExtCourse: {
        method: 'GET',
        path: (payload: {
          externalCourseTargetOrganizationId: string | number;
        }) =>
          `external-courses/single-target-org/${payload.externalCourseTargetOrganizationId}`,
      },
      getExternalCoursesByUserId: {
        method: 'GET',
        path: (payload: { userId: string | number }) =>
          `external-courses/enrolled-courses-for-students-by-admin/${payload.userId}`,
      },
      updateExtCourseTargetOrg: {
        method: 'POST',
        path: (payload: {
          externalCourseTargetOrganizationId: string | number;
        }) =>
          `external-courses/single-target-org/${payload.externalCourseTargetOrganizationId}`,
      },
    },
    controlBase: {
      udacity: {
        getCatalog: {
          method: 'GET',
          path: () => `external-courses/udacity-catalog`,
          params: {} as { skip?: string | number; take?: string | number },
        },
      },
      cornell: {
        getCatalog: {
          method: 'GET',
          path: () => `external-courses/cornell-catalog`,
          params: {} as { skip?: string | number; take?: string | number },
        },
      },
      learnerProgress: {
        syncLearnerProgressWithUdacity: {
          method: 'POST',
          path: () => `external-courses/sync-udacity-progress`,
        },
        testingReporting: {
          method: 'POST',
          path: () => `external-courses/udacity-query-reports`,
        },
        learnerProgressForCourse: {
          method: 'POST',
          path: (payload: { externalCourseEnrollmentId: string | number }) =>
            `external-courses/progress-per-user/${payload.externalCourseEnrollmentId}`,
        },
      },
      syncAllCatalogs: {
        method: 'GET',
        path: () => `external-courses/sync-all-catalogs`,
      },
      allExternalCourses: {
        method: 'GET',
        path: () => `external-courses/control-base/courses/all`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getPendingEnrollments: {
        method: 'GET',
        path: () => `external-courses/pending-enrollments`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getAllEnrolleesForExtCourse: {
        method: 'GET',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/all-enrollees/${payload.externalCourseId}`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      updateEnrollmentStatus: {
        method: 'POST',
        path: () => `external-courses/update-enrollment-status`,
      },
      createOrUpdateExternalCourseTargetOrg: {
        method: 'POST',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/create-external-course-target-organization/${payload.externalCourseId}`,
      },
      getSingleExternalCourseById: {
        method: 'GET',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/${payload.externalCourseId}`,
      },
      getAllTargetOrganizationsForCourse: {
        method: 'GET',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/${payload.externalCourseId}/target-orgs`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getSingleTargetOrgForExtCourse: {
        method: 'GET',
        path: (payload: {
          externalCourseTargetOrganizationId: string | number;
        }) =>
          `external-courses/single-target-org/${payload.externalCourseTargetOrganizationId}`,
      },
      deleteSingleTargetOrgForExtCourse: {
        method: 'DELETE',
        path: (payload: {
          externalCourseTargetOrganizationId: string | number;
        }) =>
          `external-courses/single-target-org/${payload.externalCourseTargetOrganizationId}`,
      },
    },
    student: {
      coursesForStudent: {
        method: 'GET',
        path: () => `external-courses/available-courses-for-students`,
        params: {} as { skip?: string | number; take?: string | number },
      },
      getSingleCourseForStudent: {
        method: 'GET',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/${payload.externalCourseId}/student`,
      },
      enrolledCourses: {
        method: 'GET',
        path: () => `external-courses/enrolled-courses-for-students`,
      },
      launchCornellCourse: {
        method: 'GET',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/${payload.externalCourseId}/launch-cornell`,
      },
      learnerProgressForCornellCourse: {
        method: 'POST',
        path: (payload: { externalCourseEnrollmentId: string | number }) =>
          `external-courses/progress-per-user/${payload.externalCourseEnrollmentId}`,
      },
      enroll: {
        method: 'POST',
        path: (payload: { externalCourseId: string | number }) =>
          `external-courses/${payload.externalCourseId}/enroll`,
      },
    },
    tODO: {
      test: {
        method: 'POST',
        path: () => `external-courses/test`,
      },
      test2: {
        method: 'POST',
        path: () => `external-courses/test2`,
      },
    },
  },
};
