import {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permissions.enum';
const allCoursesMeta = {
  // Users don't need to be logged in to view the courses. What determines the visibility of the courses are the organization's permissions (if the user is not logged in) or the user's permissions (if the user is logged in).
  requiresAuth: false,
  memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
  organizationPermissions: [EnumOrganizationPermissions.LMS],
  shouldHaveSelfEnrollment: true,
  shouldAllowAnonymousCourseView: true,
  name: `${import.meta.env.VITE_DEFAULT_TITLE} | All Courses`,
  groupMeta: undefined,
};
const myCoursesMeta = {
  requiresAuth: true,
  memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
  organizationPermissions: [],
  name: `${import.meta.env.VITE_DEFAULT_TITLE} | My Courses`,
  groupMeta: undefined,
};
const finishedCoursesMeta = {
  requiresAuth: true,
  memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
  organizationPermissions: [],
  name: `${import.meta.env.VITE_DEFAULT_TITLE} | Finished Courses`,
  groupMeta: undefined,
};
export const lms = {
  root: {
    name: 'LMS',
    path: '/lms',
    title: 'header.courses',
    icon: 'mdi-bookshelf',
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Courses`,
      groupMeta: [myCoursesMeta, finishedCoursesMeta, allCoursesMeta],
      dontUseKey: true,
    },
  },
  specialization: {
    name: 'Specialization',
    title: 'course.specializations',
    path: '/lms/specializations',
    icon: 'mdi-bookshelf',
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Courses`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
      organizationPermissions: [EnumOrganizationPermissions.LMS],
    },
  },
  myCourses: {
    name: 'My Courses',
    path: '/lms/my-courses',
    title: 'course.myCourses',
    icon: 'mdi-bookshelf',
    meta: myCoursesMeta,
  },
  finishedCourses: {
    name: 'Finished Courses',
    path: '/lms/my-courses',
    title: 'course.myCourses',
    icon: 'mdi-bookshelf',
    meta: finishedCoursesMeta,
  },
  allCourses: {
    name: 'All Courses',
    title: 'header.courses',
    path: '/lms/all-courses',
    icon: 'mdi-bookshelf',
    meta: allCoursesMeta,
  },
  globalCertificatePage: {
    name: 'Global Certificate Page',
    path: '/certificates/:certificateId',
    pathFn: (certificateId: string | number) =>
      `/certificates/${certificateId}`,
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Certificate`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
      hideSideBar: true,
      hideAppBar: true,
    },
  },
  courseOverview: {
    name: 'Course Overview',
    path: '/lms/courses/:courseId/overview',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/overview`,
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Overview`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
      organizationPermissions: [EnumOrganizationPermissions.LMS],
    },
  },
  specializationOverview: {
    name: 'Specialization Overview',
    path: '/lms/specializations/:specializationId/overview',
    pathFn: (specializationId: string | number) =>
      `/lms/specializations/${specializationId}/overview`,
    meta: {
      requiresAuth: true,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Specialization Overview`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
      organizationPermissions: [EnumOrganizationPermissions.LMS],
    },
  },
  coursePage: {
    name: 'Course Page',
    path: '/lms/courses/:courseId',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}`,
    meta: {
      // Users have to be logged in to view the course content.
      // no need to specify the meta for the children routes, they will inherit the parent's meta., just define the meta for the name to override the parent's meta.
      requiresAuth: true,
      hideSideBar: true,

      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Content`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
    },
  },
  courseInformation: {
    name: 'Course Information',
    path: 'information',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/information`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Information`,
    },
  },
  courseSyllabus: {
    name: 'Course Syllabus',
    path: 'syllabus',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/syllabus`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Syllabus`,
    },
  },
  courseModules: {
    name: 'Course Modules',
    path: 'modules',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/modules`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Moudles`,
    },
  },
  courseContent: {
    name: 'Course Content',
    path: 'content/:subsectionId',
  },
  courseSubsection: 'Course Subsection',
  courseCertificate: {
    name: 'Course Certificate',
    path: 'certificate',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/certificate`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Certificate`,
    },
  },
  courseAssignments: {
    name: 'Course Assignments',
    path: 'assignments',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/assignments`,
    allCourseAssignments: {
      name: 'All Course Assignments',
      path: '',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Assignments`,
      },
    },
    singleCourseAssignment: {
      name: 'Single Course Assignment',
      path: ':assignmentId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Assignment`,
      },
    },
  },
  courseAnnouncements: {
    name: 'Course Announcements',
    path: 'announcements',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/announcements`,
    allCourseAnnouncements: {
      name: 'All Course Announcements',
      path: '',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Announcements`,
      },
    },
    singleCourseAnnouncement: {
      name: 'Single Course Announcement',
      path: ':announcementId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Announcement`,
      },
    },
  },
  courseExams: {
    name: 'Course Exams',
    path: 'exams',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/exams`,
    allCourseExams: {
      name: 'All Course Exams',
      path: '',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Exams`,
      },
    },
    examLandingPage: {
      name: 'Subsection Exam',
      path: ':subsectionExamId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Exam`,
      },
    },
    takingExam: {
      name: 'Taking Exam',
      path: ':subsectionExamId/start',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Exam`,
      },
    },
    reviewExam: {
      name: 'Review Exam',
      path: 'review/:examAttemptId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Exam Review`,
      },
    },
  },
  courseSurveys: {
    surveys: {
      name: 'Course Surveys',
      path: 'surveys',
      pathFn: (courseId: string | number) => `/lms/courses/${courseId}/surveys`,
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Surveys`,
      },
    },
    polls: {
      name: 'Course Polls',
      path: 'polls',
      pathFn: (courseId: string | number) => `/lms/courses/${courseId}/polls`,
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Polls`,
      },
    },
    singlePoll: {
      name: 'Single Poll',
      path: 'polls/:surveyId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Poll Content`,
      },
    },
    singleSurvey: {
      name: 'Single Survey',
      path: 'surveys/:surveyId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Survey Content`,
      },
    },
  },
  courseOnlineLectures: {
    name: 'Course Online Lectures',
    path: 'online-lectures',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/online-lectures`,
    allOnlineLectures: {
      name: 'All Online Lectures',
      path: '',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Course Online Lectures`,
      },
    },
    singleOnlineLecture: {
      name: 'Single Online Lecture',
      path: ':onlineLectureId',
      meta: {
        name: `${import.meta.env.VITE_DEFAULT_TITLE} | Online Lecture Content`,
      },
    },
  },
  courseOnCampusLectures: {
    name: 'Course OnCampus Lectures',
    path: 'on-campus-lectures',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/on-campus-lectures`,
    allOnCampusLectures: {
      name: 'All On-Campus Lectures',
      path: '',
      meta: {
        name: `${
          import.meta.env.VITE_DEFAULT_TITLE
        } | Course On-Campus Lectures`,
      },
    },
    singleOnCampusLecture: {
      name: 'Single On-Campus Lecture',
      path: ':onCampusLectureId',
      meta: {
        name: `${
          import.meta.env.VITE_DEFAULT_TITLE
        } | On-Campus Lecture Content`,
      },
    },
  },
  courseGradeItems: {
    name: 'Course Grade Items',
    path: 'grade-items',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/grade-items`,
    allGradeItems: {
      name: 'All Grade Items',
      path: '',
    },
    singleGradeItem: {
      name: 'Single Grade Item',
      path: ':gradeItemId',
    },
  },
  courseNotesItems: {
    name: 'Course Notes Items',
    path: 'notes',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/notes`,
  },
  courseDiscussionBoards: {
    name: 'Course Discussion Boards',
    path: 'discussion-boards',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/discussion-boards`,
    allDiscussionBoards: {
      name: 'All Discussion Boards',
      path: '',
      meta: {
        name: `${
          import.meta.env.VITE_DEFAULT_TITLE
        } | Course Discussion Boards`,
      },
    },
    singleDiscussionBoard: {
      name: 'Single Discussion Board',
      path: ':discussionBoardId',
      meta: {
        name: `${
          import.meta.env.VITE_DEFAULT_TITLE
        } | Discussion Board Content`,
      },
    },
  },
  courseAttendance: {
    name: 'Course Attendance',
    path: 'attendance',
    pathFn: (courseId: string | number) =>
      `/lms/courses/${courseId}/attendance`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Attendance`,
    },
  },
  courseGroups: {
    name: 'Course Groups',
    path: 'groups',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/groups`,
    meta: {
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Groups`,
    },
  },
  courseReview: {
    name: 'Review Course',
    path: '/lms/courses/:courseId/review',
    pathFn: (courseId: string | number) => `/lms/courses/${courseId}/review`,
    meta: {
      requiresAuth: false,
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Review Course`,
      memberPermissions: [EnumOrganizationMemberPermissions.AccessCourses],
      organizationPermissions: [EnumOrganizationPermissions.LMS],
    },
  },
};
